export const CANADA = {
  abbreviation: "CA",
  alpha2Code: "ca",
  ccTld: "ca",
  currency: {
    code: "CAD",
    symbol: "$",
  },
  freeze: {
    durationInMonths: {
      medical: 6,
      pregnancy: 12,
      regular: 2,
    },
    feePerFifteenDays: 25,
    feePerMonth: 50,
  },
  id: 6,
  locale: "en-CA",
  name: "Canada",
};

export const UNITED_KINGDOM = {
  abbreviation: "UK",
  alpha2Code: "gb",
  ccTld: "uk",
  currency: {
    code: "GBP",
    symbol: "£",
  },
  freeze: {
    durationInMonths: {
      medical: 6,
      pregnancy: 9,
      regular: 2,
    },
    feePerFifteenDays: 20,
    feePerMonth: 40,
  },
  id: 5,
  locale: "en-GB",
  name: "United Kingdom",
};

export const UNITED_STATES = {
  abbreviation: "US",
  alpha2Code: "us",
  ccTld: "us",
  currency: {
    code: "USD",
    symbol: "$",
  },
  freeze: {
    durationInMonths: {
      medical: 6,
      pregnancy: 9,
      regular: 2,
    },
    feePerFifteenDays: 25,
    feePerMonth: 50,
  },
  id: 1,
  locale: "en-US",
  name: "United States",
};

const COUNTRIES = [CANADA, UNITED_KINGDOM, UNITED_STATES];

const byAbbreviation = {};
const byName = {};

COUNTRIES.forEach((country) => {
  byAbbreviation[country.abbreviation] = country;
});

COUNTRIES.forEach((country) => {
  byName[country.name] = country;
});

export { byAbbreviation, byName };

export default COUNTRIES;
